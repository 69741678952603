import React from 'react';
import './component.css';
const WebsiteDevelopmentTop = () => {
  return (
    <div className="container-fluid web_top">
      <div className="row">
        <div className="col-1"></div>
        <div className="col-sm-10">
          <h2 className="Website_heading">Your Expert Partner in Website Development</h2>
          <hr className="web_line" />
          <p className="Website_sub">
            At Hypertonic It Solutions, we understand the pivotal role that a well-designed and functional website plays in establishing a strong online presence for your business. With the ever-growing importance of digital platforms, having a professionally crafted website is no longer a luxury but a necessity for businesses of all sizes and industries.
          </p>
        </div>
        <div className="col-1"></div>
      </div>
    </div>
  );
};

export default WebsiteDevelopmentTop;
