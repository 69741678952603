import React from 'react';
import { Link } from 'react-router-dom';
import Nav from '../../components/Nav/Nav';
import Footer from '../../components/Footer/Footer';
import Section36 from '../../components/Section36/Component';
import Section37 from '../../components/Section37/Component';


const Web = () => {
  return (
    <>
      <Nav />
      <Section36 />
      <Section37 />
      <Footer />
      </>
  );
}

export default Web;
