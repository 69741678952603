import React from 'react';
import { Link } from 'react-router-dom';
import Nav from '../../components/Nav/Nav';
import Footer from '../../components/Footer/Footer';
import Section17 from '../../components/Section17/Component';
import Section18 from '../../components/Section18/Component';
import Section19 from '../../components/Section19/Component';
import Section20 from '../../components/Section20/Component';

const Software = () => {
  return (
    <>
      <Nav />
      <Section17 />
      <Section18 />
      <Section19 />
      <Section20 />

      <Footer />
      </>
  );
}

export default Software;
