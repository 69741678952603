import React from 'react';
import './component.css';

const TermsAndConditions = () => {
  return (
    <div>
      
      <div className="container-fluid background_img">
        <div className="row">
          <div className="col-sm-12">
            <p className="term_condition">Terms and Conditions</p>
          </div>
        </div>
      </div>
      <div className="container top_privacy">
        <div className="row">
          <div className="col-sm-12">
            <p className="privacy_hedaing">Introduction</p>
            <hr className="hr_privacy_line" />
            <p className="privacy_topline">
              The terms "We" / "Us" / "Our"/”Company” individually and collectively refer to Hypertonic It Solutions
              and the terms "Visitor” ”User” refer to the users.
            </p>
            <p className="privacy_topline">
              This T&C applies to the services offered by Hypertonic It Solutions at hypertonic.co.in, its subdomains.
            </p>
            <p className="privacy_topline">
              This page states the Terms and Conditions under which you (Visitor) may visit this website (“Website”)
              or Hypertonic It Solutions. Please read this page carefully. If you do not accept the Terms and Conditions stated here, we would request
              you to exit this site. The business, any of its business divisions and / or its subsidiaries, associate companies or subsidiaries
              to subsidiaries or such other investment companies (in India or abroad) reserve their respective rights to revise these Terms and
              Conditions at any time by updating this posting. You should visit this page periodically to re-appraise yourself of the Terms and
              Conditions, because they are binding on all users of this Website.
            </p>
            <p className="privacy_hedaing">Use Of Content</p>
            <hr className="hr_privacy_line" />
            <p className="privacy_topline">
              All logos, brands, marks headings, labels, names, signatures, numerals, shapes or any combinations thereof,
              appearing in this site, except as otherwise noted, are properties either owned, or used under licence, by the business and / or its associate
              entities who feature on this Website. The use of these properties or any other content on this site, except as provided in these terms and
              conditions or in the site content, is strictly prohibited.
            </p>
            <p className="privacy_topline">
              You may not sell or modify the content of this Website or reproduce, display, publicly perform, distribute,
              or otherwise use the materials in any way for any public or commercial purpose without the respective organisation’s or entity’s written permission.
            </p>
            <p className="privacy_hedaing">Acceptable Website Use</p>
            <hr className="hr_privacy_line" />
            <p className="privacy_hedaing1">1. Security Rules</p>
            <p className="privacy_topline">
              Visitors are prohibited from violating or attempting to violate the security of the Web site, including,
              without limitation, (1) accessing data not intended for such user or logging into a server or account which the user is not authorised
              to access, (2) attempting to probe, scan or test the vulnerability of a system or network or to breach security or authentication measures
              without proper authorisation, (3) attempting to interfere with service to any user, host or network, including, without limitation, via means
              of submitting a virus or "Trojan horse" to the Website, overloading, "flooding", "mail bombing" or "crashing", or (4) sending unsolicited
              electronic mail, including promotions and/or advertising of products or services. Violations of system or network security may result in civil
              or criminal liability. The business and / or its associate entities will have the right to investigate occurrences that they suspect as involving
              such violations and will have the right to involve, and cooperate with, law enforcement authorities in prosecuting users who are involved in such
              violations.
            </p>
            <p className="privacy_hedaing1">2. General Rules</p>
            <p className="privacy_topline">
              Visitors may not use the Web Site in order to transmit, distribute, store or destroy material (a) that could
              constitute or encourage conduct that would be considered a criminal offence or violate any applicable law or regulation, (b) in a manner
              that will infringe the copyright, trademark, trade secret or other intellectual property rights of others or violate the privacy or publicity
              of other personal rights of others, or (c) that is libellous, defamatory, pornographic, profane, obscene, threatening, abusive or hateful.
              We reserve the right to refuse service to anyone for any reason at any time.
            </p>
            <p className="privacy_hedaing">Reservation of Rights</p>
            <hr className="hr_privacy_line" />
            <p className="privacy_topline">
              We reserve the right to request that you remove all links or any particular link to our website.
              You agree to immediately remove all links to our website upon request. We also reserve the right to amend these terms and conditions
              and the linking policy at any time. By continuously linking to our website, you agree to be bound by and follow these linking terms
              and conditions.
            </p>
            <p className="privacy_hedaing">Disclaimer</p>
            <hr className="hr_privacy_line" />
            <p className="privacy_topline">
              The information on the hypertonic website is solely meant to provide general information. Make no judgments
              regarding your business, your legal situation, or anything else based on the content or information on the website.
            </p>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default TermsAndConditions;
