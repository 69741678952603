import React from 'react';
import './component.css';

const SoftwareDevelopmentServices = () => {
  return (
    <div className="container-fluid web_top1">
      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-10">
          <p className="Website_heading1">Our Software Development Services Include:</p>
          <hr className="web_line1" />
          <ol>
            <li className="li-no">
              <p className="Website_subcontent">
                <span className="content_heading">Custom Software Development:</span> Whether you need a standalone desktop application, a web-based application, or a mobile app, we have the expertise to develop custom software solutions that address your unique requirements and deliver tangible results for your business.
              </p>
            </li>
            <li className="li-no">
              <p className="Website_subcontent">
                <span className="content_heading">Enterprise Software Solutions:</span> We specialize in developing enterprise-level software solutions that help businesses streamline their operations, improve efficiency, and drive growth. From customer relationship management (CRM) systems to enterprise resource planning (ERP) solutions, we have the experience and expertise to develop scalable software solutions that support your business objectives.
              </p>
            </li>
            <li className="li-no">
              <p className="Website_subcontent">
                <span className="content_heading">Database Management:</span> We offer comprehensive database management services to help businesses organize, store, and retrieve their data efficiently. From database design and implementation to data migration and optimization, we ensure that your databases are secure, reliable, and optimized for performance.
              </p>
            </li>
            <li className="li-no">
              <p className="Website_subcontent">
                <span className="content_heading">Software Integration and Migration:</span> We help businesses integrate their existing systems and applications or migrate to new platforms seamlessly. Whether you're consolidating your IT infrastructure, upgrading legacy systems, or integrating third-party software solutions, we have the expertise to ensure a smooth and successful transition.
              </p>
            </li>
          </ol>
        </div>
        <div className="col-sm-1"></div>
      </div>
    </div>
  );
};

export default SoftwareDevelopmentServices;
