import React from 'react';
import './component.css';

const WhyChooseHypertonic = () => {
  return (
    <div className="container-fluid web_top1">
      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-10">
          <h3 className="Website_heading1">Why Choose Hypertonic</h3>
          <hr className="web_line1" />
          <ol>
            <li className="li-no">
              <p className="Website_subcontent"><span className="content_heading">Expertise: </span> With years of experience in the mobile app development industry, our team possesses the technical skills and creative vision to develop cutting-edge apps that stand out in the crowded app marketplace.</p>
            </li>
            <li className="li-no">
              <p className="Website_subcontent"><span className="content_heading">Cross-Platform Compatibility: </span> We understand the importance of reaching a wider audience, which is why we offer cross-platform app development services. Whether your target users are on Android or iOS devices, we develop apps that perform seamlessly on both platforms, ensuring maximum reach and engagement.</p>
            </li>
            <li className="li-no">
              <p className="Website_subcontent"><span className="content_heading">Custom Solutions: </span> We believe in tailor-made solutions that cater to the unique needs and objectives of each client. From small startups to large enterprises, we work closely with you to understand your requirements and deliver custom app solutions that exceed your expectations.</p>
            </li>
            <li className="li-no">
              <p className="Website_subcontent"><span className="content_heading">User-Centric Design: </span> We place a strong emphasis on user experience (UX) and user interface (UI) design to ensure that your app not only looks great but also provides a seamless and intuitive user experience. By incorporating best practices in UX/UI design, we create apps that engage users and keep them coming back for more.</p>
            </li>
            <li className="li-no">
              <p className="Website_subcontent"><span className="content_heading">24*7 Service: </span> Whether it's the middle of the night or a holiday weekend, our team is here for you 24 hours a day, 7 days a week. You can count on us to be there when you need assistance the most.</p>
            </li>
          </ol>
        </div>
        <div className="col-sm-1"></div>
      </div>
    </div>
  );
};

export default WhyChooseHypertonic;
