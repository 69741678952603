import React from 'react';
import { Link } from 'react-router-dom';
import Nav from '../../components/Nav/Nav';
import Footer from '../../components/Footer/Footer';
import Section26 from '../../components/Section26/Component';
import Section27 from '../../components/Section27/Component';


const Web = () => {
  return (
    <>
      <Nav />
      <Section26 />
      <Section27 />
      <Footer />
      </>
  );
}

export default Web;
