import React from 'react';
import './component.css';
const VideoComponent = () => {
  return (

    <div className="container-fluid Section">
      <div className="row">
        <div className="col-sm-12 myhgt">
          <video width="100%" height="auto"  autoPlay muted loop controls={false} style={{ WebkitTransform: 'translateZ(0)', transform: 'translateZ(0)' }}  id="myVideo">
            <source src="img/WhatsApp Video 2024-05-16 at 13.22.41.mp4" type="video/mp4" className="img-fluid"/>
          </video>
        </div>
      </div>
    </div>
  );
};

export default VideoComponent;

