import React from 'react';
import './component.css';

const Add = () => {
  return (
    <div className="container-fluid background_img">
      <div className="row">
        <div className="col-sm-12">
          <h1 className="about_us">Advertising and Promotions</h1>
        </div>
      </div>
    </div>
  );
};

export default Add;
