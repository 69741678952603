import React from 'react';
import { Link } from 'react-router-dom';
import Nav from '../../components/Nav/Nav';
import Footer from '../../components/Footer/Footer';
import Section34 from '../../components/Section34/Component';
import Section35 from '../../components/Section35/Component';


const Web = () => {
  return (
    <>
      <Nav />
      <Section34 />
      <Section35 />
      <Footer />
      </>
  );
}

export default Web;
