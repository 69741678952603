import React from 'react';
import './component.css';
const Socialmedia = () => {
  return (
    <>

    <div className="container-fluid web_top">
      <div className="row">
        <div className="col-1"></div>
        <div className="col-sm-10">
          <h2 className="Website_heading">Your Expert Partner in Social Media Marketing</h2>
          <hr className="web_line" />
          <p className="Website_sub">
          At Hypertonic IT Solutions, we specialize in crafting strategic, impactful, and results-driven social media marketing campaigns across all major platforms. With our team of experienced marketers and content creators, we are dedicated to elevating your brand's online presence and driving meaningful engagement that aligns with your business goals.            </p>
        </div>
        <div className="col-1"></div>
      </div>
    </div>
    <div className="container-fluid web_top1">
      <div className="row">
        <div className="col-1"></div>
        <div className="col-sm-10">
          <h3 className="Website_heading1">Why Choose Hypertonic</h3>
          <hr className="web_line1" />
          <ol>
            <li className="li-no">
              <p className="Website_subcontent">
                <span className="content_heading">Expertise: </span>With years of experience in social media marketing, our team possesses the knowledge and creative skills to develop campaigns that stand out in the crowded social media landscape. We stay ahead of trends to ensure your brand remains relevant and engaging.
              </p>
            </li>
            <li className="li-no">
              <p className="Website_subcontent">
                <span className="content_heading">Multi-Platform Strategy: </span>We understand the importance of a broad reach, which is why we develop and implement strategies tailored to each social media platform, including Facebook, Instagram, Twitter, LinkedIn, and more. Our goal is to maximize your brand's visibility across all channels.
              </p>
            </li>
            <li className="li-no">
              <p className="Website_subcontent">
                <span className="content_heading">Tailored Campaigns: </span>We believe in creating custom social media strategies that align with your unique business objectives. Whether you're a startup looking to build brand awareness or an established company aiming to increase conversions, we work closely with you to deliver campaigns that meet your specific needs.
              </p>
            </li>
            
          </ol>
        </div>
        <div className="col-1"></div>
      </div>
    </div>
    <div className="container-fluid web_top1">
      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-10">
          <p className="Website_heading1">Our Social Media Marketing Services Include:</p>
          <hr className="web_line1" />
          <ol>
            <li className="li-no">
              <p className="Website_subcontent">
                <span className="content_heading">Social Media Strategy Development:</span>

                We create comprehensive social media strategies that align with your business goals, focusing on content planning, audience targeting, and platform optimization to maximize results.
                </p>
            </li>
            <li className="li-no">
              <p className="Website_subcontent">
                <span className="content_heading">Content Creation & Curation:</span>
                From graphics and videos to blog posts and infographics, our team produces high-quality content designed to engage your audience and drive meaningful interactions.
                </p>
            </li>
            <li className="li-no">
              <p className="Website_subcontent">
                <span className="content_heading">Social Media Advertising:</span>
                We specialize in creating and managing targeted social media ad campaigns that drive traffic, increase brand awareness, and generate leads. Our data-driven approach ensures that your ad spend is optimized for maximum ROI.
                </p>
            </li>
            
          </ol>
        </div>
        <div className="col-sm-1"></div>
      </div>
    </div>
    
    </>
  );
};

export default Socialmedia;
