import React from 'react';
import { Link } from 'react-router-dom';
import Nav from '../../components/Nav/Nav';
import Section4 from '../../components/Section4/Component'
import Footer from '../../components/Footer/Footer'

function Home() {
  return (
    <>
      <Nav />
      <Section4 />
      <Footer />
      </>
  );
}

export default Home;
