import React from 'react';
import { Link } from 'react-router-dom';
import Nav from '../../components/Nav/Nav';
import Footer from '../../components/Footer/Footer';
import Section13 from '../../components/Section13/Component';
import Section14 from '../../components/Section14/Component';
import Section15 from '../../components/Section15/Component';
import Section16 from '../../components/Section16/Component';

const AppDevelopment = () => {
  return (
    <>
      <Nav />
      <Section13 />
      <Section14 />
      <Section15 />
      <Section16 />

      <Footer />
      </>
  );
}

export default AppDevelopment;
