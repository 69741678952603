import React from 'react';
import './component.css';

const AppDevelopmentServices = () => {
  return (
    <div className="container-fluid web_top1">
      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-10">
          <p className="Website_heading1">Our Android and iOS App Development Services Include:</p>
          <hr className="web_line1" />
          <ol>
            <li className="li-no">
              <p className="Website_subcontent"><span className="content_heading">Native App Development:</span> We specialize in building native Android and iOS apps from scratch, leveraging the latest technologies and frameworks to ensure optimal performance and functionality.</p>
            </li>
            <li className="li-no">
              <p className="Website_subcontent"><span className="content_heading">Hybrid App Development:</span> Looking for a cost-effective solution that works across multiple platforms? Our hybrid app development services allow you to reach both Android and iOS users with a single codebase, saving time and resources.</p>
            </li>
            <li className="li-no">
              <p className="Website_subcontent"><span className="content_heading">App UI/UX Design:</span> Our talented designers create stunning app interfaces that are not only visually appealing but also intuitive and easy to navigate, enhancing the overall user experience and maximizing engagement.</p>
            </li>
            <li className="li-no">
              <p className="Website_subcontent"><span className="content_heading">App Testing and Quality Assurance:</span> We conduct rigorous testing and quality assurance procedures to ensure that your app functions flawlessly on all devices and platforms, delivering a seamless user experience to your target audience.</p>
            </li>
            <li className="li-no">
              <p className="Website_subcontent"><span className="content_heading">App Maintenance and Support:</span> Our commitment to client satisfaction extends beyond the initial app development phase. We offer ongoing maintenance and support services to keep your app running smoothly and up-to-date with the latest technologies and trends.</p>
            </li>
          </ol>
        </div>
        <div className="col-sm-1"></div>
      </div>
    </div>
  );
};

export default AppDevelopmentServices;
