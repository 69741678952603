import React from 'react';
import './component.css';

const SoftwareDevelopment = () => {
  return (
    <div className="container-fluid web_top">
      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-10">
          <h2 className="Website_heading">Your Expert Partner in Software Development</h2>
          <hr className="web_line" />
          <p className="Website_sub">At Hypertonic It Solutions, we specialize in providing top-notch software development solutions tailored to meet the diverse needs of businesses and organizations across various industries. With our team of skilled developers, we are committed to delivering high-quality, custom software solutions that empower our clients to streamline their operations, enhance productivity, and achieve their business objectives.</p>
        </div>
        <div className="col-sm-1"></div>
      </div>
    </div>
  );
};

export default SoftwareDevelopment;
