import React from 'react';
import './component.css';


const AppDevelopmentSection = () => {
  return (
    <div className="container-fluid web_top">
      <div className="row">
        <div className="col-1"></div>
        <div className="col-sm-10">
          <h2 className="Website_heading">Your Expert Partner in App Development: Android & iOS</h2>
          <hr className="web_line" />
          <p className="Website_sub">At Hypertonic It Solutions, we specialize in crafting high-quality, innovative, and user-friendly mobile applications for both Android and iOS platforms. With our team of experienced developers and designers, we are dedicated to bringing your app ideas to life and delivering exceptional results that meet your business objectives.</p>
        </div>
        <div className="col-1"></div>
      </div>
    </div>
  );
};

export default AppDevelopmentSection;
