import React from 'react';
import './component.css';

const PrivacyPolicy = () => {
  return (
    <div>
      <div className="container-fluid background_img">
        <div className="row">
          <div className="col-sm-12">
            <p className="term_condition">Privacy Policy</p>
          </div>
        </div>
      </div>
      <div className="container top_privacy">
        <div className="row">
          <div className="col-sm-12">
            <p className="privacy_topline">
              This Privacy Policy concerns Hypertonic It Solutions (herein referred to as ‘we’, ‘us’ and ‘our’) and every website linked to us as well as the products/services on those websites. It does not concern any third party company (we do not own), the activities of which we are not in control of. The policy is subject to alteration at our discretion. We thus recommend you to check this section time-to-time for any changes.
            </p>
            <p className="privacy_hedaing1">1. Payment Processing</p>
            <hr className="hr_privacy_line" />
            <p className="privacy_topline">
              When you decide to buy our services for your business, for the payment process you would require to enter details like your name, contact information, and account or credit/credit card details. We DO NOT STORE the information provided by you during the payment process including card/bank details, or data related to payment through any other platform. The process is carried out on secure servers of our Payment Gateway Service Providers.
            </p>
            <p className="privacy_hedaing1">2. Testimonials</p>
            <hr className="hr_privacy_line" />
            <p className="privacy_topline">
              For posting a testimonial authorized by you, we may include information like your name and other information. If you, at any point in time, wish to make changes or delete, you can contact our customer support team.
            </p>
            <p className="privacy_hedaing">Data We Collect</p>
            <hr className="hr_privacy_line" />
            <p className="privacy_topline">Based on your use of our website and/or services, we collect data chiefly from the following sources:</p>
            <p className="privacy_hedaing1">1. Requested Data</p>
            <hr className="hr_privacy_line" />
            <p className="privacy_topline">
              For availing some of our services, it requires you to enter your information which we may store & process under one the below-mentioned categories:
            </p>
            <p className="privacy_topline1"><i className="ri-checkbox-blank-circle-fill"></i> Name</p>
            <p className="privacy_topline1"><i className="ri-checkbox-blank-circle-fill"></i> Company Name</p>
            <p className="privacy_topline1"><i className="ri-checkbox-blank-circle-fill"></i> Country</p>
            <p className="privacy_topline1"><i className="ri-checkbox-blank-circle-fill"></i> Phone No.</p>
            <p className="privacy_topline1"><i className="ri-checkbox-blank-circle-fill"></i> Address</p>
            <p className="privacy_topline1"><i className="ri-checkbox-blank-circle-fill"></i> Email Address</p>
            <p className="privacy_topline1"><i className="ri-checkbox-blank-circle-fill"></i> Skype ID</p>
            <p className="privacy_topline1"><i className="ri-checkbox-blank-circle-fill"></i> Other information shared by you</p>
            <p className="privacy_hedaing1">Sharing Your Information</p>
            <hr className="hr_privacy_line" />
            <p className="privacy_topline">
              We share the sensitive personal information to any third party without obtaining the prior consent of the user in the following limited circumstances:
            </p>
            <p className="privacy_topline1">
              <i className="ri-checkbox-blank-circle-fill"></i> When it is requested or required by law or by any court or governmental agency or authority to disclose, for the purpose of verification of identity, or for the prevention, detection, investigation including cyber incidents, or for prosecution and punishment of offences. These disclosures are made in good faith and belief that such disclosure is reasonably necessary for enforcing these Terms; for complying with the applicable laws and regulations.
            </p>
            <p className="privacy_topline1">
              <i className="ri-checkbox-blank-circle-fill"></i> We propose to share such information within its group companies and officers and employees of such group companies for the purpose of processing personal information on its behalf. We also ensure that these recipients of such information agree to process such information based on our instructions and in compliance with this Privacy Policy and any other appropriate confidentiality and security measures.
            </p>
            <p className="privacy_hedaing">Purpose of Collecting Data</p>
            <hr className="hr_privacy_line" />
            <p className="privacy_topline">We collect your data for purposes like:</p>
            <p className="privacy_topline1">1. For smooth communication while processing the project for which you’ve signed the contact with us.</p>
            <p className="privacy_topline1">2. To inform about important notices, changes in Privacy Policy or Terms of Service.</p>
            <p className="privacy_topline1">3. To keep you updated about any new services/products, offers, promotions, upcoming events and other things that we feel might interest you.</p>
            <p className="privacy_topline1">4. To ask for your feedback on services/products provided by us.</p>
            <p className="privacy_topline1">5. To create and maintain your account for future reference, project development & deployment, requirement gathering etc.</p>
            <p className="privacy_topline1">6. To understand your use of our website & services in order to enhance user experience.</p>
            <p className="privacy_topline1">7. To track the latest trends and enhance marketing campaigns to make helpful suggestions to our clients & users.</p>
            <p className="privacy_hedaing">Cookies</p>
            <hr className="hr_privacy_line" />
            <p className="privacy_topline">
              To improve the responsiveness of the sites for our users, we may use "cookies", or similar electronic tools to collect information to assign each visitor a unique, random number as a User Identification (User ID) to understand the user's individual interests using the Identified Computer. Unless you voluntarily identify yourself (through registration, for example), we will have no way of knowing who you are, even if we assign a cookie to your computer. The only personal information a cookie can contain is information you supply (an example of this is when you ask for our Personalised Horoscope). A cookie cannot read data off your hard drive. Our advertisers may also assign their own cookies to your browser (if you click on their ads), a process that we do not control.
            </p>
            <p className="privacy_hedaing">Complaints</p>
            <hr className="hr_privacy_line" />
            <p className="privacy_topline">
              Any complaints, abuse or concerns with regards to content and or comment or breach of these terms shall be immediately informed to info@hypertonic.co.in.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
