import React from 'react';
import { Link } from 'react-router-dom';
import Nav from '../../components/Nav/Nav';
import Footer from '../../components/Footer/Footer';
import Section32 from '../../components/Section32/Component';
import Section33 from '../../components/Section33/Component';


const Web = () => {
  return (
    <>
      <Nav />
      <Section32 />
      <Section33 />
      <Footer />
      </>
  );
}

export default Web;
