import React from 'react';
import './component.css';

const WhyChooseHypertonic = () => {
  return (
    <div className="container-fluid web_top1">
      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-10">
          <h3 className="Website_heading1">Why Choose Hypertonic</h3>
          <hr className="web_line1" />
          <ol>
            <li className="li-no">
              <p className="Website_subcontent">
                <span className="content_heading">Expertise: </span> With years of experience in the software development industry, our team possesses the technical skills and domain knowledge to tackle even the most complex software development projects. From web-based applications to enterprise-level software solutions, we have the expertise to deliver results that exceed your expectations.
              </p>
            </li>
            <li className="li-no">
              <p className="Website_subcontent">
                <span className="content_heading">Custom Solutions: </span> We believe in creating bespoke software solutions that address the unique needs and challenges of each client. Whether you're a small startup or a large enterprise, we work closely with you to understand your requirements and develop custom software solutions that are tailored to your specific business goals and objectives.
              </p>
            </li>
            <li className="li-no">
              <p className="Website_subcontent">
                <span className="content_heading">Cutting-Edge Technologies: </span> We stay abreast of the latest technologies and trends in the software development industry to ensure that our clients benefit from the most advanced solutions available. From cloud-based architectures to artificial intelligence and machine learning, we leverage cutting-edge technologies to develop software solutions that are innovative, scalable, and future-proof.
              </p>
            </li>
            <li className="li-no">
              <p className="Website_subcontent">
                <span className="content_heading">Agile Methodology: </span> We follow agile software development methodologies to ensure that our projects are completed on time and within budget. By breaking down projects into smaller, manageable tasks and iterating quickly based on feedback, we are able to deliver high-quality software solutions that meet our clients' evolving needs and expectations.
              </p>
            </li>
            <li className="li-no">
              <p className="Website_subcontent">
                <span className="content_heading">24*7 Service: </span> Whether it's the middle of the night or a holiday weekend, our team is here for you 24 hours a day, 7 days a week. You can count on us to be there when you need assistance the most.
              </p>
            </li>
          </ol>
        </div>
        <div className="col-sm-1"></div>
      </div>
    </div>
  );
};

export default WhyChooseHypertonic;
