import React from 'react';
import { Link } from 'react-router-dom';
import Nav from '../../components/Nav/Nav';
import Footer from '../../components/Footer/Footer';
import Section30 from '../../components/Section30/Component';
import Section31 from '../../components/Section31/Component';


const Web = () => {
  return (
    <>
      <Nav />
      <Section30 />
      <Section31 />
      <Footer />
      </>
  );
}

export default Web;
