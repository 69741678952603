import React from 'react';
import './component.css';
const Smm = () => {
  return (
    <>

    <div className="container-fluid web_top">
      <div className="row">
        <div className="col-1"></div>
        <div className="col-sm-10">
          <h2 className="Website_heading">Your Expert Partner in Social Media Management</h2>
          <hr className="web_line" />
          <p className="Website_sub">
          At Hypertonic IT Solutions, we specialize in creating and executing comprehensive social media strategies that elevate your brand presence and foster meaningful engagement with your audience. With our team of experienced social media managers and creative experts, we are dedicated to building your online community and driving impactful results that align with your business goals.        </p>
        </div>
        <div className="col-1"></div>
      </div>
    </div>
    <div className="container-fluid web_top1">
      <div className="row">
        <div className="col-1"></div>
        <div className="col-sm-10">
          <h3 className="Website_heading1">Why Choose Hypertonic</h3>
          <hr className="web_line1" />
          <ol>
            <li className="li-no">
              <p className="Website_subcontent">
                <span className="content_heading">Expertise: </span>With years of experience in the social media landscape, our team has the strategic insight and technical know-how to craft compelling social media campaigns that resonate with your target audience. We stay ahead of the latest trends to ensure your brand remains relevant and engaging.
              </p>
            </li>
            
          </ol>
        </div>
        <div className="col-1"></div>
      </div>
    </div>
    <div className="container-fluid web_top1">
      <div className="row">
        <div className="col-sm-1"></div>
        <div className="col-sm-10">
          <p className="Website_heading1">Our Social Media Management Services Include:</p>
          <hr className="web_line1" />
          <ol>
            <li className="li-no">
              <p className="Website_subcontent">
                <span className="content_heading">Social Media Strategy Development:</span>

                  We craft data-driven social media strategies that align with your business goals, target audience, and industry trends. Our strategies are designed to increase brand awareness, drive traffic, and generate leads.      
                </p>
            </li>
            <li className="li-no">
              <p className="Website_subcontent">
                <span className="content_heading">Content Creation and Curation:</span>
                We produce a steady stream of high-quality content, including images, videos, infographics, and articles, tailored to your brand and audience. We also curate relevant third-party content to keep your social media profiles fresh and engaging.
                </p>
            </li>
            <li className="li-no">
              <p className="Website_subcontent">
                <span className="content_heading">Community Management:</span>
                We actively engage with your audience, responding to comments, messages, and reviews in a timely and professional manner. Our community management services help build a loyal following and foster positive relationships with your customers.
            </p>
            </li>
            
          </ol>
        </div>
        <div className="col-sm-1"></div>
      </div>
    </div>
    
    </>
  );
};

export default Smm;
